import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sortByString } from "lib/Utilities";
import { createStructure } from "./store/actions/structuresActions";
import { createInspectionFormsStructure } from "./store/actions/inspectionFormsStructuresActions";

// Helpful to understand refs
// https://reactjs.org/docs/refs-and-the-dom.html#callback-refs

class AddInspectionFormStructureInlineFormBase extends React.Component {
  static propTypes = {
    parentId: PropTypes.number.isRequired,
    inspectionForms: PropTypes.array.isRequired,
    handleAddInspectionFormClick: PropTypes.func.isRequired,
    handleCancelClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.inspectionFormSelect = null;
    this.setInspectionFormSelectRef = (element) => {
      this.inspectionFormSelect = element;
    };
    this.focusInput = () => {
      if (this.inspectionFormSelect) this.inspectionFormSelect.focus();
    };
  }

  componentDidMount() {
    this.focusInput();
  }

  submit = (e) => {
    e.preventDefault();
    const formId = this.inspectionFormSelect.value;
    this.props.handleAddInspectionFormClick(formId, this.props.parentId);
    this.focusInput();
  };

  cancel = () => {
    this.props.handleCancelClick(this.props.parentId);
  };

  linkToCreateNewForm = () => {
    const newFormUrl = "/setup/inspection_forms/new";
    window.location.href = newFormUrl;
  };

  render() {
    let formOptions = this.props.inspectionForms.map((form) => (
      <option key={form.id} value={form.id}>
        {form.name}
      </option>
    ));

    return (
      <ul>
        <li>
          <div
            className="form-inline"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                this.submit(e);
              }
            }}
          >
            <p>
              <i className="fal fa-file-alt right-margin"></i>
              <select
                className="form-control"
                ref={this.setInspectionFormSelectRef}
              >
                {formOptions}
              </select>

              <input
                type="submit"
                value="Save"
                className="btn btn-default left-margin"
                onClick={this.submit}
              />

              <a className="left-margin" onClick={this.cancel}>
                Cancel
              </a>
            </p>
            <p>
              or
              <a className="left-margin" onClick={this.linkToCreateNewForm}>
                Create New Inspection Form 
              </a>
            </p>
          </div>
        </li>
      </ul>
    );
  }
}

const getSortedInspectionForms = (state) => {
  const formsById = state.inspectionForms.inspectionFormsById;
  let forms = [];
  for (let id in formsById) {
    if (formsById.hasOwnProperty(id)) {
      forms.push(formsById[id]);
    }
  }
  return sortByString(forms, (form) => form.name);
};

const mapStateToProps = (state, ownProps) => {
  return {
    inspectionForms: getSortedInspectionForms(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleAddInspectionFormClick: (formId, structureId) => {
      dispatch(createInspectionFormsStructure(formId, structureId));
    },
  };
};

const AddInspectionFormStructureInlineForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInspectionFormStructureInlineFormBase);

export default AddInspectionFormStructureInlineForm;
