import { sortByString } from "lib/Utilities";

// https://javascript.info/class
export default class Structure {

  constructor(structure) {
    Object.assign(this, {
      childrenIds: [],
      inspectionFormsStructuresIds: [],
      isLoadingRelationships: false,
      hasLoadedRelationships: false,
    }, structure)
  }

  get fullName() {
    if(this.job_number)
      return `(${ this.job_number }) ${ this.name }`
    else
      return this.name
  }

  get hasChildren() {
    return this.active_children_count > 0
  }

  get hasInspectionFormsStructures() {
    return this.inspectionFormsStructuresIds && this.inspectionFormsStructuresIds.length > 0
  }

  get hasDescendant() {
    return this.hasChildren || this.hasInspectionFormsStructures
  }

  children(state) {
    if (this.active_children_count === 0) { return [] }

    const structuresById = state.structures.structuresById
    let children = []
    this.childrenIds.forEach( id => children.push(structuresById[id]) )
    return sortByString(children, o => o.name)
  }

  // Might need to change to a selector: https://redux.js.org/recipes/computing-derived-data
  inspectionFormStructures(state) {
    if (this.active_children_count > 0 || this.inspectionFormsStructuresIds.length == 0) { return [] }

    const ifsById = state.inspectionFormsStructures.inspectionFormsStructuresById
    let inspectionFormsStructures = []
    this.inspectionFormsStructuresIds.forEach( (id) => {
      let inspectionFormStructure = {...ifsById[id]}
      const formId = inspectionFormStructure.inspection_form_id
      const form = state.inspectionForms.inspectionFormsById[formId]
      if (form) {
        inspectionFormStructure.name = form.name
      }
      else {
        inspectionFormStructure.name = "Loading..."
      }
      inspectionFormsStructures.push(inspectionFormStructure)
    })
    return sortByString(inspectionFormsStructures, o => o.name)
  }

  get ancestryIds() {
    return this.ancestry.split("/").map( o => parseInt(o) )
  }

}
