import { Rest, fetchAndPagePromise as fetchAndPage } from "lib/Utilities";
import { addInspectionFormsStructures } from "./inspectionFormsStructuresActions";
/*
 * Action types
 */

// API related actions
export const DELETE_STRUCTURE = "DELETE_STRUCTURE"
export const UPDATE_STRUCTURE = "UPDATE_STRUCTURE"

export const ADD_STRUCTURES = "ADD_STRUCTURES"
export const LOADING_RELATIONSHIPS_FOR_STRUCTURE = "LOADING_RELATIONSHIPS_FOR_STRUCTURE"
export const LOADED_RELATIONSHIPS_FOR_STRUCTURE = "LOADED_RELATIONSHIPS_FOR_STRUCTURE"

// UI related actions
export const SELECT_STRUCTURE = "SELECT_STRUCTURE"
export const SET_TOP_LEVEL_STRUCTURE = "SET_TOP_LEVEL_STRUCTURE"

/*
 * Action creators
 */


export function addStructures(objects, id) {
  return {
    type: ADD_STRUCTURES,
    objects: objects,
    parentId: id
  }
}

export function setTopLevelStructure(id) {
  return {
    type: SET_TOP_LEVEL_STRUCTURE,
    id: id
  }
}

export function selectStructure(id) {
  return {
    type: SELECT_STRUCTURE,
    id: id
  }
}

export function deleteStructure(id) {
  return {
    type: DELETE_STRUCTURE,
    id: id
  }
}

export function updateStructure(structure) {
  return {
    type: UPDATE_STRUCTURE,
    object: structure
  }
}

const loadingRelationships = (id) => {
  return {
    type: LOADING_RELATIONSHIPS_FOR_STRUCTURE,
    id: id,
  }
}

const loadedRelationships = (id) => {
  return {
    type: LOADED_RELATIONSHIPS_FOR_STRUCTURE,
    id: id,
  }
}

// Async

export function createStructure(name, parentId) {
  return dispatch => {
    const params = {
      structure: {
        name: name,
        parent_id: parentId
      }
    }

    const uri = '/api/v4/structures.json'
    Rest.post(uri, params).then(object => {
      const structure = object.structure
      dispatch(addStructures([structure], structure.structure_id))
    })
  }
}

const fetchChildren = (id) => {
  return dispatch => {
    dispatch(loadingRelationships(id))

    const uri = `/api/v4/structures/${ id }/structures.json`
    return fetchAndPage(uri, {fat_response: 1}, 'structures').then( structures => {
      dispatch(loadedRelationships(id))
      // grab inspection form structures from structures
      const ifs = [].concat.apply([], structures.map( s => s.inspection_forms_structures ))

      // Don't want to send a dispatch for each of them so we have to do
      // the work of loadedRelationshipsForStructure here.
      structures.forEach( (structure) => {
        if (structure.inspection_forms_structures.length > 0 ||
        (structure.active_children_count === 0 && structure.inspection_forms_structures.length === 0)) {
          structure.isLoadingRelationships = false
          structure.hasLoadedRelationships = true
        }
      })

      dispatch(addStructures(structures, id))
      dispatch(addInspectionFormsStructures(ifs))
    })
  }
}

const fetchInspectionFormsStructures = (id) => {
  return dispatch => {
    dispatch(loadingRelationships(id))

    const uri = `/api/v4/structures/${ id }/inspection_forms_structures.json`
    return fetchAndPage(uri, {}, 'inspection_forms_structures').then( inspectionFormStructures => {
      dispatch(loadedRelationships(id))
      dispatch(addInspectionFormsStructures(inspectionFormStructures, id))
    })
  }
}

export function fetchRelationshipsForStructure(structure) {
  if (structure.hasChildren) {
    return fetchChildren(structure.id)
  }
  else {
    return fetchInspectionFormsStructures(structure.id)
  }
}
